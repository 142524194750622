import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const AsterSurveyPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const hasSeenSurvey = localStorage.getItem('hasSeenAsterSurvey');
      if (!hasSeenSurvey) {
        setIsOpen(true);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleRedirect = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLScmYcnhYzglCk3E5bnVRc72vr1RwYlFK9oG1xrMSDzRieYkFg/viewform?usp=header', '_blank');
    localStorage.setItem('hasSeenAsterSurvey', 'true');
    setIsOpen(false);
  };

  const handleClose = () => {
    localStorage.setItem('hasSeenAsterSurvey', 'true');
    setIsOpen(false);
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={handleClose} className="relative z-50">
        <style jsx global>{`
          @keyframes pulse-scale {
            0% {
              transform: scale(1);
              box-shadow: 0 0 0 0 rgba(0, 150, 255, 0.4);
            }
            70% {
              transform: scale(1.05);
              box-shadow: 0 0 0 10px rgba(0, 150, 255, 0);
            }
            100% {
              transform: scale(1);
              box-shadow: 0 0 0 0 rgba(0, 150, 255, 0);
            }
          }

          .pulse-button {
            animation: pulse-scale 2s infinite;
            transform-origin: center;
          }

          .pulse-button:hover {
            animation: none;
            transform: scale(1.05);
          }
        `}</style>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-md aspect-[9/16] overflow-hidden rounded-2xl">
              <div className="relative h-full w-full">
                <img 
                  src="/images/aster-survey.png" 
                  alt="Aster Survey" 
                  className="w-full h-full object-cover"
                />
                
                <div className="absolute inset-x-0 top-1/2 flex justify-center">
                  <div className="flex flex-col items-center gap-4 w-64">
                    <button
                      onClick={handleRedirect}
                      className="w-full px-8 py-3 bg-[#0096FF] text-white rounded-lg font-medium shadow-lg pulse-button hover:bg-[#0077CC] transition-colors"
                    >
                      Je participe !
                    </button>
                    <button
                      onClick={handleClose}
                      className="w-full px-8 py-3 bg-white text-[#0096FF] rounded-lg hover:bg-gray-100 transition-colors font-medium shadow-lg opacity-70 hover:opacity-100"
                    >
                      Plus tard
                    </button>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AsterSurveyPopup;